<template>
    <fieldset class="mb-3 pb-3">
        <legend class="text-2xl mb-2">Dados básicos</legend>
        <div class="grid md:grid-cols-12 md:gap-4">
            <div class="form-group md:col-span-6">
                <label for="name" class="">Nome</label>
                <input
                    type="text"
                    v-model="form.name"
                    id="name"
                    class="form-field"
                />
                <div v-if="form.errors.name" class="text-sm text-red-600">
                    {{ form.errors.name }}
                </div>
            </div>
            <div class="form-group md:col-span-3">
                <label for="phone" class="">Telefone (whatsapp)</label>
                <input
                    type="text"
                    v-model="form.phone"
                    id="phone"
                    class="form-field"
                    @input="formatPhone"
                />
                <div v-if="form.errors.phone" class="text-sm text-red-600">
                    {{ form.errors.phone }}
                </div>
            </div>
            <div class="form-group md:col-span-3">
                <label for="document" class="">CPF</label>
                <input
                    type="text"
                    v-model="form.document"
                    id="document"
                    class="form-field"
                    @input="formatCPF"
                />
                <div v-if="form.errors.document" class="text-sm text-red-600">
                    {{ form.errors.document }}
                </div>
            </div>
        </div>

        <div class="grid md:grid-cols-4 md:gap-4">
            <div class="form-group">
                <label for="email" class="">Email</label>
                <input
                    type="email"
                    v-model="form.email"
                    id="email"
                    class="form-field"
                />
                <div v-if="form.errors.email" class="text-sm text-red-600">
                    {{ form.errors.email }}
                </div>
            </div>

            <div class="form-group">
                <label for="password" value="Senha" class="">Senha</label>
                <input
                    id="password"
                    type="password"
                    class="form-field"
                    v-model="form.password"
                    required
                    autofocus
                />
                <div v-if="form.errors.password" class="text-sm text-red-600">
                    {{ form.errors.password }}
                </div>
            </div>
        </div>
    </fieldset>

    <fieldset class="mb-3 pb-3">
        <legend class="text-2xl mb-2">Dados complementares</legend>

        <div class="grid md:grid-cols-12 md:gap-4">
            <div class="form-group md:col-span-3">
                <label for="real_id">RG</label>
                <input
                    type="text"
                    v-model="form.real_id"
                    id="real_id"
                    class="form-field"
                />
                <div v-if="form.errors.real_id" class="text-sm text-red-600">
                    {{ form.errors.real_id }}
                </div>
            </div>
            <div class="form-group md:col-span-3">
                <label for="birth_date">Data de nascimento</label>
                <input
                    type="date"
                    v-model="form.birth_date"
                    id="birth_date"
                    class="form-field"
                />
                <div v-if="form.errors.birth_date" class="text-sm text-red-600">
                    {{ form.errors.birth_date }}
                </div>
            </div>

            <div class="form-group md:col-span-3">
                <label for="gender">Sexo</label>
                <select v-model="form.gender" id="gender" class="form-field">
                    <option
                    class="custom-select"
                        v-for="gender in genders"
                        :key="gender.id"
                        :value="gender.id"
                    >
                        {{ gender.name }}
                    </option>
                </select>
                <div v-if="form.errors.gender" class="text-sm text-red-600">
                    {{ form.errors.gender }}
                </div>
            </div>

            <div class="form-group md:col-span-3">
                <label for="nationality">Nacionalidade</label>
                <input
                    type="text"
                    v-model="form.nationality"
                    id="nationality"
                    class="form-field"
                />
                <div
                    v-if="form.errors.nationality"
                    class="text-sm text-red-600"
                >
                    {{ form.errors.nationality }}
                </div>
            </div>
        </div>
        <div class="grid md:grid-cols-12 md:gap-4"></div>
    </fieldset>

    <fieldset class="mb-3 pb-3">
        <legend class="text-2xl mb-2">Endereço</legend>

        <div class="grid md:grid-cols-12 md:gap-4">
            <div class="form-group md:col-span-2">
                <label for="address_zipcode" class="">CEP</label>
                <input
                    type="text"
                    v-model="form.address_zipcode"
                    id="address_zipcode"
                    class="form-field"
                />
                <div
                    v-if="form.errors.address_zipcode"
                    class="text-sm text-red-600"
                >
                    {{ form.errors.address_zipcode }}
                </div>
            </div>

            <div class="form-group md:col-span-4">
                <label for="address_street" class="">Logradouro</label>
                <input
                    type="text"
                    v-model="form.address_street"
                    id="address_street"
                    class="form-field"
                />
                <div
                    v-if="form.errors.address_street"
                    class="text-sm text-red-600"
                >
                    {{ form.errors.address_street }}
                </div>
            </div>

            <div class="form-group md:col-span-2">
                <label for="address_number" class="">Número</label>
                <input
                    type="text"
                    v-model="form.address_number"
                    ref="address_number"
                    id="address_number"
                    class="form-field"
                />
                <div
                    v-if="form.errors.address_number"
                    class="text-sm text-red-600"
                >
                    {{ form.errors.address_number }}
                </div>
            </div>

            <div class="form-group md:col-span-4">
                <label for="address_complement" class="">Complemento</label>
                <input
                    type="text"
                    v-model="form.address_complement"
                    id="address_complement"
                    class="form-field"
                />
                <div
                    v-if="form.errors.address_complement"
                    class="text-sm text-red-600"
                >
                    {{ form.errors.address_complement }}
                </div>
            </div>
        </div>

        <div class="grid md:grid-cols-12 md:gap-4">
            <div class="form-group md:col-span-5">
                <label for="address_district" class="">Bairro</label>
                <input
                    type="text"
                    v-model="form.address_district"
                    id="address_district"
                    class="form-field"
                />
                <div
                    v-if="form.errors.address_district"
                    class="text-sm text-red-600"
                >
                    {{ form.errors.address_district }}
                </div>
            </div>

            <div class="form-group md:col-span-5">
                <label for="address_city" class="">Cidade</label>
                <input
                    type="text"
                    v-model="form.address_city"
                    id="address_city"
                    class="form-field"
                />
                <div
                    v-if="form.errors.address_city"
                    class="text-sm text-red-600"
                >
                    {{ form.errors.address_city }}
                </div>
            </div>

            <div class="form-group md:col-span-2">
                <label for="address_state" class="">Estado (UF)</label>
                <input
                    type="text"
                    v-model="form.address_state"
                    id="address_state"
                    class="form-field"
                />
                <div
                    v-if="form.errors.address_state"
                    class="text-sm text-red-600"
                >
                    {{ form.errors.address_state }}
                </div>
            </div>
        </div>
    </fieldset>

    <fieldset class="mb-3 pb-3">
        <legend class="text-2xl mb-2">Escolaridade</legend>

        <div class="grid md:grid-cols-12 md:gap-4">
            <div class="form-group md:col-span-6">
                <label for="educational_level_id" class=""
                    >Nível de ensino</label
                >
                <select
                    v-model="form.educational_level_id"
                    id="educational_level_id"
                    class="form-field"
                    placeholder="Ensino Médio"
                >
                    <option value="2" selected>Ensino Médio</option>
                </select>
            </div>
            <div class="form-group md:col-span-6">
                <label for="educational_instituition_name" class=""
                    >Instituição de ensino</label
                >
                <v-autocomplete
                theme="dark"
                class="custom-select hide-overlay"
                elevation="0"
                density="compact"
                v-model="form.educational_instituition_name"
                :items="instituicao"
                :item-title="'name'"
                :item-value="'name'"
                >

                </v-autocomplete>

                <div
                    v-if="form.errors.educational_instituition_name"
                    class="text-sm text-red-600"
                >
                    {{ form.errors.educational_instituition_name }}
                </div>
            </div>
        </div>

        <div class="grid md:grid-cols-12 md:gap-4">
            <div class="form-group md:col-span-4">
                <label for="course" class="">Curso</label>
                <input v-model="form.course" id="course" class="form-field" />
                <div v-if="form.errors.course" class="text-sm text-red-600">
                    {{ form.errors.course }}
                </div>
            </div>

            <div class="form-group md:col-span-4">
                <label for="grade" class="">Série</label>
                <input v-model="form.grade" id="grade" class="form-field" />
                <div v-if="form.errors.grade" class="text-sm text-red-600">
                    {{ form.errors.grade }}
                </div>
            </div>

            <div class="form-group md:col-span-4">
                <label for="register" class="">RA ou Número de Matrícula</label>
                <input
                    v-model="form.register"
                    id="register"
                    class="form-field"
                />
                <div v-if="form.errors.register" class="text-sm text-red-600">
                    {{ form.errors.register }}
                </div>
            </div>
        </div>
    </fieldset>

    <fieldset class="mb-3 pb-3">
        <legend class="text-2xl mb-2">Documentos (Arquivos)</legend>

        <div class="grid md:grid-cols-12 md:gap-4">
            <div class="form-group col-span-3">
                <label for="file_selfie" class="">Selfie (JPG, PNG)</label>
                <input
                    type="file"
                    id="file_selfie"
                    @input="form.file_selfie = $event.target.files[0]"
                    class="rounded w-full p-2 border border-gray-500"
                />
                <progress
                    v-if="form.progress"
                    :value="form.progress.percentage"
                    max="100"
                >
                    {{ form.progress.percentage }}%
                </progress>

                <div
                    v-if="form.errors.file_selfie"
                    class="text-sm text-red-600"
                >
                    {{ form.errors.file_selfie }}
                </div>

                <div v-if="estudent?.file_selfie" class="my-8">
                    <p class="mb-2">Selfie atual:</p>
                    <img
                        :src="estudent.file_selfie_url"
                        alt=""
                        style="width: 100%"
                    />
                </div>
            </div>

            <div class="form-group col-span-3">
                <label for="file_document" class=""
                    >Documento Frente (JPG, PNG)</label
                >
                <input
                    type="file"
                    id="file_document"
                    @input="form.file_document = $event.target.files[0]"
                    class="rounded w-full p-2 border border-gray-500"
                />
                <progress
                    v-if="form.progress"
                    :value="form.progress.percentage"
                    max="100"
                >
                    {{ form.progress.percentage }}%
                </progress>

                <div
                    v-if="form.errors.file_document"
                    class="text-sm text-red-600"
                >
                    {{ form.errors.file_document }}
                </div>

                <div v-if="estudent?.file_document" class="my-8">
                    <p class="mb-2">Documento frente atual:</p>
                    <img
                        :src="estudent.file_document_url"
                        alt=""
                        style="width: 100%"
                    />
                </div>
            </div>

            <div class="form-group col-span-3">
                <label for="file_document_back" class=""
                    >Documento verso (JPG, PNG, PDF)</label
                >
                <input
                    type="file"
                    id="file_document_back"
                    @input="form.file_document_back = $event.target.files[0]"
                    class="rounded w-full p-2 border border-gray-500"
                />
                <progress
                    v-if="form.progress"
                    :value="form.progress.percentage"
                    max="100"
                >
                    {{ form.progress.percentage }}%
                </progress>

                <div
                    v-if="form.errors.file_document_back"
                    class="text-sm text-red-600"
                >
                    {{ form.errors.file_document_back }}
                </div>

                <div v-if="estudent?.file_document_back" class="my-8">
                    <p class="mb-2">Documento verso atual:</p>
                    <img
                        :src="estudent.file_document_back_url"
                        alt=""
                        style="width: 100%"
                    />
                </div>
            </div>

            <div class="form-group col-span-3">
                <label for="file_certificate" class=""
                    >Cert. de escolaridade (JPG, PNG, PDF)</label
                >
                <input
                    type="file"
                    id="file_certificate"
                    @input="form.file_certificate = $event.target.files[0]"
                    class="rounded w-full p-2 border border-gray-500"
                />
                <progress
                    v-if="form.progress"
                    :value="form.progress.percentage"
                    max="100"
                >
                    {{ form.progress.percentage }}%
                </progress>

                <div
                    v-if="form.errors.file_certificate"
                    class="text-sm text-red-600"
                >
                    {{ form.errors.file_certificate }}
                </div>

                <div v-if="estudent?.file_certificate" class="my-8">
                    <p class="mb-2">Certificado de escolaridade atual:</p>
                    <img
                        :src="estudent.file_certificate_url"
                        alt=""
                        style="width: 100%"
                    />
                </div>
            </div>
        </div>
    </fieldset>

    <div class="text-center mt-8">
        <vue-hcaptcha
            sitekey="7bf11c18-d23c-40c9-a9ca-0ca2b3d1000c"
            @verify="onVerify"
            @expired="onExpire"
            @challenge-expired="onChallengeExpire"
            @error="onError"
        ></vue-hcaptcha>
    </div>
    <div class="flex justify-between items-center mt-8">
        <button
            type="submit"
            :class="[
                'text-white',
                'px-4',
                'py-2',
                'rounded',
                'btn',
                'btn_enviar',
                {
                    'cursor-not-allowed': form.processing || !captchaVerified,
                    'opacity-50': form.processing || !captchaVerified,
                },
            ]"
            class="bg-green-500 hover:bg-green:700 text-white px-4 py-2 rounded btn btn_enviar"
            :disabled="form.processing || !captchaVerified"
        >
            Enviar informações
        </button>
    </div>
</template>

<script>
import { useForm } from "@inertiajs/inertia-vue3";
import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";
import axios from "axios";

export default {
    name: "NewEstudentForm",
    components: { useForm, VueHcaptcha},
    props: {
        form: {
            type: useForm,
        },
        instituicao: {
            type: Array,
        },
        estudent: {
            type: Object,
        },
    },

    data: () => {
        return {
            genders: [
                { id: "M", name: "Masculino" },
                { id: "F", name: "Feminino" },
            ],
            captchaVerified: false,
            instituitions: [],
            partner: null,
        };
    },
    watch: {
        "form.address_zipcode"(zipcode) {
            this.form.address_city = "carregando...";
            this.form.address_street = "carregando...";
            this.form.address_district = "carregando...";
            this.form.address_state = "carregando...";
            this.form.address_number = "";
            this.addressByZipcode(zipcode);
        },
    },
    mounted() {
        console.log("FORM", this.form);
        const script = document.createElement("script");
        script.src = "https://js.hcaptcha.com/1/api.js";
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
    },
    methods: {
        formatCPF() {
            let cpf = this.form.document.replace(/\D/g, "");
            cpf = cpf.slice(0, 11);
            if (cpf.length > 3) {
                cpf = cpf.replace(/(\d{3})(?=\d)/g, "$1.");
            }
            if (cpf.length > 6) {
                cpf = cpf.replace(/(\d{3})(?=\d)/g, "$1.");
            }
            if (cpf.length > 9) {
                cpf = cpf.replace(
                    /(\d{3})(\d{3})(\d{3})(\d{2})$/,
                    "$1-$2-$3-$4"
                );
            }
            if (cpf.length == 14) {
                let doisUltimosDigitos = cpf.substring(cpf.length - 2);
                let novoCpf = cpf.substring(0, cpf.length - 3) + "-";
                cpf = novoCpf + doisUltimosDigitos;
            }

            this.form.document = cpf;
        },
        formatPhone(event) {
            let phoneNumber = event.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos
    let formattedPhoneNumber = "";
    phoneNumber = phoneNumber.slice(0, 11); // Limita o número de telefone a 11 dígitos

    if (phoneNumber.length > 0) {
      formattedPhoneNumber += `(${phoneNumber.substring(0, 2)}`; // Adiciona o código de área entre parênteses

      if (phoneNumber.length > 2) {
        formattedPhoneNumber += `) ${phoneNumber.substring(2, 7)}`; // Adiciona o primeiro bloco do número
      }
      if (phoneNumber.length > 7) {
        formattedPhoneNumber += `-${phoneNumber.substring(7)}`; // Adiciona o segundo bloco do número
      }
    }


    this.form.phone = formattedPhoneNumber;
        },
        onVerify(token, eKey) {
            // console.log('Verified: ', { token, eKey })
            this.form.h_captcha_response = token;
            this.captchaVerified = true;
        },
        onExpire() {
            console.log("Token expired");
            this.captchaVerified = false;
        },
        onChallengeExpire() {
            console.log("Challenge expired");
            this.captchaVerified = false;
        },
        onError(err) {
            console.log("Error", err);
            this.captchaVerified = false;
        },
        addressByZipcode(zipcode) {
            if (zipcode.length >= 8) {
                zipcode = zipcode.replace("-", "");
                axios
                    .get(`https://viacep.com.br/ws/${zipcode}/json`)
                    .then((res) => {
                        this.form.address_street = res.data.logradouro;
                        this.form.address_district = res.data.bairro;
                        this.form.address_city = res.data.localidade;
                        this.form.address_state = res.data.uf;
                    })
                    .catch((err) =>
                        console.error("Erro ao consultar cep", err)
                    );
            }
        },
        // listEducationalInstituitions(level_id) {
        //   axios
        //     .get(`/educational-instituitions/by-level/${level_id}`)
        //     .then((res) => {
        //       this.instituitions = res.data;
        //     })
        //     .catch((err) => console.error("Erro ao consultar instituições", err));
        // },
    },
};
</script>

<style>
legend {
    font-family: "Yummo";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
}
label {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff !important;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.form-field {
    color: #ffffff !important;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    background-color: transparent;
    border-style: none;
    border: none !important;
    border-bottom: 1px solid #ffffff !important;
    outline: none !important; /* Opcional para remover a aparência de foco no input */
}
.custom-select {
    color: #fff;
    background-color: #043980;
  }
input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    filter: brightness(0) invert(1);
}
input[type="file"] {
    color: black;
    background-color: #043980;
}
input[type="text"] {
    color: #fff;
    background-color: transparent;
}
.hide-overlay >>> .v-input__control .v-field .v-field-overlay {
  display: none;
}
.btn_enviar {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 8px;
    width: 1166px;
    height: 46px;
    background: #e7ac00;
    border: 1px solid #e7ac00;
    border-radius: 10px;
    flex: none;
    order: 5;
    align-self: stretch;
    flex-grow: 0;
}
.text-center {
    display: flex;
    justify-content: center;
}
@media screen and (orientation: portrait), (max-width: 1000px) {
    .btn_enviar {
        width: 85vw;
    }
}
</style>
